<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "Catalogs",
};
</script>


<style scoped>
</style>


